<template>
  <PageContainer
    :title="$translations.reviews.title"
    :back="actions.back"
    :submit="actions.submit"
  >
    <template v-slot:content>
      <!-- skeleton -->
      <div class="page-segment" v-if="loading">
        <div class="page-segment-content">
          <div v-for="index in 3" :key="index">
            <Skeleton width="150px" height="20px" borderRadius="16px" />
            <Skeleton
              margin="16px 0"
              width="100%"
              height="96px"
              borderRadius="16px"
            />
          </div>

        </div>
      </div>
      <div class="page-segment" v-if="appointment && !loading">
        <div class="page-segment-content">
          <!-- video rating -->
          <div class="margin-top margin-bottom-lg">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.reviews['video-title']"
            />
            <StarReview @select="selection('video', $event)" />
          </div>


          <!-- specialist rating -->
          <div class="margin-top margin-bottom-lg">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.reviews['specialist-title']"
            />
            <StarReview @select="selection('specialist', $event)" />
          </div>


          <!-- comments -->
          <div class="margin-top margin-bottom-lg">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.reviews.comments"
            />
            <TextareaField
              :label="$translations.reviews.placeholder"
              maxLength="250"
              @change="selection('comments', $event)"
            />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>


<script>
import {
  Appointments,
  PageContainer,
  Reviews,
  StarReview,
  Skeleton
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'

export default {
  name: 'AppointmentReview',
  async mounted () {
    await this.getAppointment()
  },
  data () {
    return {
      form: {
        specialist: null,
        video: null,
        comments: null
      },
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.push({
                name: 'AppointmentDetails',
                params: {
                  id: this.$route.params.id
                }
              })
            }
          }
        },
        submit: {
          text: this.$translations.reviews.submit,
          callback: () => {
            this.submit()
          },
          loading: false
        }
      },
      appointment: null,
      loading: false
    }
  },
  components: {
    PageContainer,
    StarReview,
    TextareaField,
    Skeleton
  },
  methods: {
    selection (field, value) {
      this.form[field] = value
    },
    submit () {
      this.actions.submit.loading = true

      const createSpecialistReviewPromise = Reviews.createReviewV3({
        reference_type: "SPECIALIST",
        reference_id: this.appointment.specialist.id,
        content: this.form.comments,
        rating: +this.form.specialist,
        recommended: +this.form.specialist >= 3,
        appointment: this.appointment.id,
        specialist: this.appointment.specialist.id
      })

      const createAppointmentReviewPromise = Reviews.createReviewV3({
        reference_type: "MEET",
        reference_id: this.appointment.id,
        content: this.form.comments,
        rating: +this.form.video,
        recommended: +this.form.video >= 3,
        appointment: this.appointment.id,
        specialist: this.appointment.specialist.id
      }).catch()

      Promise.all([
        createSpecialistReviewPromise,
        createAppointmentReviewPromise
      ]).then(() => {
        this.actions.submit.loading = false
        this.$toast({
          severity: 'success',
          text: this.$translations.aftercall.modals.reviews.toast.success
        })
        this.$router.push({
          name: 'AppointmentDetails',
          params: {
            id: this.appointment.id
          }
        })
      }).catch(() => {
          this.actions.submit.loading = false
          this.$toast({
            severity: 'error',
            text: this.$translations.aftercall.modals.reviews.toast.error
          })
        })
    },
    async getAppointment () {
      this.loading = true

      await Appointments.getUsrAppointmentDetail(
        this.$route.params.id
      ).then((response) => {
        this.appointment = response.appointment
      })

      this.loading = false
    },
  }
}
</script>

<style lang="sass" scoped>
::v-deep
  .review-options
    justify-content: flex-start
  .icon-star-on,
  .icon-star-off
    font-size: 36px
</style>
